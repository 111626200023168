// @flow strict
import { ICONS } from "../constants";
import {
  faTwitter,
  faGithub,
  faLinkedin,
  faYoutube,
  faSoundcloud,
  faFacebook,
  faVk,
  faTelegramPlane,
  faInstagram,
  faLine,
  faGitlab,
  faWeibo,
  faCodepen,
  faMedium,
  faResearchgate,
} from "@fortawesome/free-brands-svg-icons";
import { faGraduationCap, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const getIcon = (name) => {
  let icon;

  switch (name) {
    case "twitter":
      icon = faTwitter;
      break;
    case "github":
      icon = faGithub;
      break;
    case "vkontakte":
      icon = faVk;
      break;
    case "telegram":
      icon = faTelegramPlane;
      break;
    case "email":
      icon = faEnvelope;
      break;
    case "linkedin":
      icon = faLinkedin;
      break;
    case "instagram":
      icon = faInstagram;
      break;
    case "line":
      icon = faLine;
      break;
    case "facebook":
      icon = faFacebook;
      break;
    case "gitlab":
      icon = faGitlab;
      break;
    case "weibo":
      icon = faWeibo;
      break;
    case "codepen":
      icon = faCodepen;
      break;
    case "youtube":
      icon = faYoutube;
      break;
    case "soundcloud":
      icon = faSoundcloud;
      break;
    case "medium":
      icon = faMedium;
      break;
    case "scholar":
      icon = faGraduationCap;
      break;
    case "researchgate":
      icon = faResearchgate;
      break;
    default:
      icon = {};
      break;
  }

  return icon;
};

export default getIcon;
