// @flow strict
import React from "react";
import { withPrefix, Link } from "gatsby";
import * as styles from "./Author.module.scss";
import { MDXRenderer } from "gatsby-plugin-mdx";

const Author = ({ author, isIndex }) => (
  <div className={styles["author"]}>
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles["author__photo"]}
        width="175"
        height="175"
        alt={author.name}
      />
    </Link>

    {!isIndex && (
      <h3 className={styles["author__title"]}>
        <Link className={styles["author__titleLink"]} to="/">
          {author.name}
        </Link>
      </h3>
    )}
    <div className={styles["author__subtitle"]}>
      <MDXRenderer>{author.bio}</MDXRenderer>
    </div>
  </div>
);

export default Author;
