// @flow strict
import React from "react";
import { getContactHref, getIcon } from "../../../utils";
import Icon from "../../Icon";
import * as styles from "./Contacts.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contacts = ({ contacts }) => (
  <div className={styles["contacts"]}>
    <ul className={styles["contacts__list"]}>
      {Object.keys(contacts).map((name) =>
        !contacts[name] ? null : (
          <li className={styles["contacts__listItem"]} key={name}>
            <a
              className={styles["contacts__listItemLink"]}
              href={getContactHref(name, contacts[name])}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon name={name} icon={getIcon(name)} />
            </a>
          </li>
        )
      )}
    </ul>
  </div>
);

export default Contacts;
